<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>ENTRADAS/SALIDAS</h2>
        <v-row>
          <v-col cols=12>
            <v-row>
              <v-col cols=3 class="pl-10">
                <v-autocomplete label="Producto" solo v-model="filter.product" :items="products" item-text="name" chips
                  return-object />
              </v-col>
              <v-col cols=3 class="pl-10">
                <v-select label="Variante" solo v-if="filter.product != null" v-model="filter.productVariant"
                  :items="filter.product.product_variants" item-text="name" return-object>
                </v-select>
              </v-col>
              <v-col cols=2 class="pl-10">
                <v-select label="Talla" solo v-if="filter.productVariant != null" v-model="filter.productSize"
                  :items="filter.productVariant.product_sizes" item-text="name" return-object>
                </v-select>
              </v-col>
              <v-col cols=2 class="pl-10">
                <DMYDateField label="Desde" v-model="filter.fromDate"></DMYDateField>
                <DMYDateField label="Hasta" v-model="filter.toDate"></DMYDateField>
              </v-col>
              <v-col cols=1>
                <v-checkbox :disabled="filter.onlyOuts" v-model="filter.onlyIns" label="Solo entradas"></v-checkbox>
                <v-checkbox :disabled="filter.onlyIns" v-model="filter.onlyOuts" label="Solo salidas"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols=12>
            <v-btn @click="applyFilter()" class="green lighten-2" dark>Buscar</v-btn>
          </v-col>
        </v-row>
        <CRUDIndex :permissionRoot="'Alumnos,Doyang Alumnos'" :headers="headers" :items=items :storeModel="storeModel"
          :itemsPerPage="itemsPerPage" :searchPlaceholder="'Referencia'" :apiPagination="true" :totalItems="totalItems"
          :runModelRequest="false" @pageUpdated="(num) => { this.pageNum = num; sendPaginationOptions({}) }"
          @sortedBy="(column) => { this.sortBy = column; sendPaginationOptions({}) }"
          @sortedDesc="(boolean) => { this.sortDesc = boolean; sendPaginationOptions({}) }" @apiSearch="apiSearch"
          :loading=loading />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  watch: {
    "filter.product": function () {
      this.filter.productVariant = null
      this.filter.productSize = null
    },
    "filter.productVariant": function () {
      this.filter.productSize = null
    },
  },
  mounted() {
    this.sendPaginationOptions({})
    this.$store.dispatch('Product/index', `?doyang_id=${this.selectedDoyang.id}&with=product_variants,product_variants.product_sizes,multilevel_discount_type`)
  },
  methods: {
    applyFilter() {
      this.sendPaginationOptions({ search: this.searchText })
    },
    sendPaginationOptions({ search = null }) {
      const page = this.pageNum
      this.loading = true
      const productSearch = this.filter.product?.id ? `&product_id=${this.filter.product.id}` : ''
      const productVariantSearch = this.filter.productVariant?.id ? `&product_variant_id=${this.filter.productVariant.id}` : ''
      const productSizeSearch = this.filter.productSize?.id ? `&product_size_id=${this.filter.productSize.id}` : ''
      const searchText = search ? `&search=${search}` : ''
      const onlyInsSearch = this.filter.onlyIns ? `&only_ins=1` : ''
      const onlyOutsSearch = this.filter.onlyOuts ? `&only_outs=1` : ''
      const fromDateSearch = this.filter.fromDate ? `&from_date=${this.filter.fromDate}` : ''
      const toDateSearch = this.filter.toDate ? `&to_date=${this.filter.toDate}` : ''
      this.$store.dispatch('InventoryMove/index', `?doyang_id=${this.selectedDoyang.id}&orderBy[created_at]=desc&with=product_size&page=${page}${productSearch}${productVariantSearch}${productSizeSearch}${searchText}${onlyInsSearch}${onlyOutsSearch}${fromDateSearch}${toDateSearch}`).then(() => {
        this.loading = false
      })
    },
    apiSearch(text) {
      this.searchText = text
      this.sendPaginationOptions({ search: text })
    },
  },
  computed: {
    products() {
      let products = this.$store.getters['Product/products'] || []
      return products
    },
    items() {
      let data = this.$store.getters['InventoryMove/inventory_moves']?.data || []
      if (typeof data === 'object')
        data = Object.values(data)
      return data
    },
    totalItems() {
      console.log('aaaaaaaaaaa', this.$store.getters['InventoryMove/inventory_moves'])
      return this.$store.getters['InventoryMove/inventory_moves']?.pagination?.total
    },
    itemsPerPage() {
      return this.$store.getters['InventoryMove/inventory_moves']?.pagination?.per_page
    },
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang']
    },
  },
  data() {
    return {
      searchText: '',
      filter: {
        fromDate: null,
        toDate: null,
        product: null,
        productVariant: null,
        productSize: null,
        onlyOuts: false,
        onlyIns: false,
      },
      pageNum: 1,
      payload: `?`,
      loading: false,
      headers: [
        { text: 'Entrada/Salida', value: 'is_turn_in' },
        { text: 'Total', value: 'total_after_move' },
        { text: 'Fecha', value: 'parsed_created_at' },
        { text: 'Producto', value: 'product_size.full_name' },
        { text: 'Referencia', value: 'reference' },
        { text: 'Comentario', value: 'observation' },
        { text: 'Cantidad', value: 'qty' },
      ],
      storeModel: 'InventoryMove',
      storeModelPlural: 'InventoryMoves'
    }
  },
}
</script>

<style></style>
