import { render, staticRenderFns } from "./DoyangCard.vue?vue&type=template&id=2d227de5&scoped=true"
import script from "./DoyangCard.vue?vue&type=script&lang=js"
export * from "./DoyangCard.vue?vue&type=script&lang=js"
import style0 from "./DoyangCard.vue?vue&type=style&index=0&id=2d227de5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d227de5",
  null
  
)

export default component.exports