import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    inventory_moves: [],
    inventory_move: [],
  },
  mutations: {
    index(state, payload) {
      state.inventory_moves = payload
    },
    show(state, payload) {
      state.inventory_move = payload
    },
    delete(state, payload) {
      state.inventory_moves.data = state.inventory_moves.data.filter(item => item.id !== payload.id) ?? []
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/inventory_moves${payload ? payload : ''}`).then((res) => {
        const inventory_moves = res.data
        commit('index', inventory_moves)
        return inventory_moves
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/inventory_moves/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/inventory_moves`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/inventory_moves/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    delete({ commit }, item) {
      return axios.delete(`${ENV.API_URL}/api/inventory_moves/${item}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    inventory_moves: state => {
      return state.inventory_moves
    },
    inventory_move: state => {
      return state.inventory_move
    },
  },
  modules: {}
}
